import { Path } from '@cedalo/webui/src/helper/Path';
import theme from '@cedalo/webui/src/theme';
import { StreamSheetAppBar } from '@cedalo/webui/src/ui/StreamSheetAppBar';
import StreamSheetNavigation from '@cedalo/webui/src/ui/StreamSheetNavigation';
import CustomTooltip from '@cedalo/webui/src/ui/utils/CustomTooltip';
import StreamSheetBreadCrumbs from '@cedalo/webui/src/ui/utils/StreamSheetBreadCrumbs';
import StreamSheetPaper from '@cedalo/webui/src/ui/utils/StreamSheetPaper';
import SettingsPaper from '@cedalo/webui/src/ui/utils/SettingsPaper';
import AddIcon from '@mui/icons-material/esm/Add';
import { Box, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { CreateUserView } from './CreateUserView';
import { UpdateUserView } from './UpdateUserView';
import { UserTableView } from './UserTableView';
import { withUser } from '@cedalo/webui/src/UserProvider';

const DetailView = ({ userId }) => {
	const navigate = useNavigate();
	if (userId === 'new') {
		return <CreateUserView onSubmit={() => navigate(Path.users())} onCancel={() => navigate(Path.users())} />;
	}
	return <UpdateUserView userId={userId} />;
};

export const UserPage = withUser(({ user: { rights } }) => ({ rights }))(
	injectIntl(({ intl, rights }) => {
		const { userId } = useParams();
		const [filter, setFilter] = useState('');
		const navigate = useNavigate();

		document.title = intl.formatMessage({ id: 'TabTitle.Users' }, {});

		const drawerWidth = 60;

		return (
			<div style={{ width: 'inherit', height: 'inherit' }}>
				<div>
					<StreamSheetAppBar
						key="appPageBar"
						helpContext="users"
						showFilter
						onFilterChange={(newFilter) => setFilter(newFilter.toLowerCase())}
					/>
					<StreamSheetNavigation openPage={navigate} />
				</div>
				<Box
					sx={{
						position: 'relative',
						height: 'calc(100% - 50px)',
						width: `calc(100% - ${drawerWidth}px)`,
						marginLeft: `${drawerWidth}px`,
						overflow: 'hidden',
						// @ts-ignore
						bgcolor: theme.wall.backgroundColor
					}}
				>
					<div
						style={{
							display: 'inline-flex',
							width: 'calc(100% - 15px)',
							justifyContent: 'space-between'
						}}
					>
						<StreamSheetBreadCrumbs path="Home/Users" openPage={navigate} />
						<div
							style={{
								alignItems: 'end',
								display: 'flex',
								minHeight: '10px',
								marginTop: '5px'
							}}
						>
							<div
								style={{
									display: 'flex'
								}}
							>
								<CustomTooltip header="Tooltip.AddUser" message="Tooltip.AddUserMessage">
									<div>
										{rights.includes('user.edit') ? (
											<IconButton
												aria-label="Add User"
												onClick={() => navigate(Path.user('new'))}
												size="small"
											>
												<AddIcon />
											</IconButton>
										) : null}
									</div>
								</CustomTooltip>
							</div>
						</div>
					</div>
					<StreamSheetPaper data-tour="page-users">
						<Box
							sx={{
								height: 'calc(100%)',
								width: 'calc(100% - 342px)'
							}}
							data-tour="page-user"
						>
							<UserTableView
								selectedUser={userId}
								onSelectUser={(id) => navigate(Path.user(id))}
								filterText={filter}
								onDeleteUser={(id) => (id === userId ? navigate(Path.users()) : null)}
							/>
						</Box>
						<SettingsPaper
							open
							helpContext="usersettings"
							title={
								userId === 'new' ? (
									<FormattedMessage id="UserPage.NewSettings" defaultMessage="User Setttings" />
								) : (
									<FormattedMessage id="UserPage.EditSettings" defaultMessage="User Setttings" />
								)
							}
						>
							{userId ? (
								<DetailView userId={userId} />
							) : (
								<Box sx={{ p: 3, display: 'flex', justifyContent: 'center' }}>
									<Typography color="text.primary" variant="h5">
										<FormattedMessage id="UserPage.NoUser" defaultMessage="No user selected" />
									</Typography>
								</Box>
							)}
						</SettingsPaper>
					</StreamSheetPaper>
				</Box>
			</div>
		);
	})
);
