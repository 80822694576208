import React from 'react';
import { useParams } from 'react-router-dom';
import * as IntegrationsDelegate from './IntegrationsDelegate';
import { AccountsPage } from '../components/accounts/AccountsPage';

export function IntegrationsPage() {
	const { integrationId } = useParams();

	return (
		<AccountsPage accountId={integrationId} delegate={IntegrationsDelegate} breadcrumbsPath="Home/Integrations" />
	);
}
