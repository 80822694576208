import { intl } from '@cedalo/webui/src/helper/IntlGlobalProvider';
import { runQuery, useQuery } from '@cedalo/webui/src/ui/app/GraphQLWSClient';
import { Overlay } from '@cedalo/webui/src/ui/utils/Overlay';
import { withUser } from '@cedalo/webui/src/UserProvider';
import CheckIcon from '@mui/icons-material/esm/Check';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import React, { useEffect, useReducer } from 'react';
import { UpdatePasswordForm } from './UpdatePasswordForm';
import { UpdateUserForm } from './UpdateUserForm';

const QUERY = `
query UpdateUserForm($id: ID!) {
	user(id: $id) {
		id
		admin
		username
		email
		lastName
		firstName
		provider
		scopes {
			id
			role
		}
	}
	workspaces {
		id
		name
	}
	roles
}
`;

const UPDATE_USER_MUTATION = `
mutation UpdateUser($id: ID!, $user: UpdateUserInput!) {
	updateUser(id: $id, user: $user) {
		user {
			id
			username
		}
		success
		code
		fieldErrors {
			username
			email
		}
	}
}
`;

const UPDATE_PASSWORD_MUTATION = `
mutation UpdateUser($id: ID!, $newPassword: String!) {
	updateUserPassword(id: $id, newPassword: $newPassword) {
		success
		code
		fieldErrors {
			password
		}
	}
} 
`;

const hasFieldError = (errors) => Object.entries(errors).filter(([key, value]) => key !== 'form' && !!value).length > 0;

const updateUserReducer = (state, action) => {
	switch (action.type) {
		case 'init': {
			const { user, workspaces, roles, userId } = action.data;
			const scopes = user.admin
				? []
				: user.scopes.map((s) => {
						const workspace = workspaces.find((ws) => ws.id === s.id);
						const name = workspace ? workspace.name : '';
						return {
							...s,
							name
						};
				  });
			const scopeSet = new Set(scopes.map((s) => s.id));
			return {
				...state,
				user: {
					...user,
					scopes
				},
				userId,
				originalUser: user,
				workspaces,
				roles,
				availableWorkspaces: workspaces.filter((s) => !scopeSet.has(s.id))
			};
		}
		case 'set_username':
			return {
				...state,
				pristine: false,
				user: { ...state.user, username: action.data },
				errors: {
					...state.errors,
					username: !action.data ? 'USERNAME_REQUIRED' : undefined
				}
			};
		case 'set_email':
			return {
				...state,
				pristine: false,
				user: { ...state.user, email: action.data },
				errors: {
					...state.errors,
					email: !action.data ? 'EMAIL_REQUIRED' : undefined
				}
			};
		case 'set_first_name':
			return {
				...state,
				pristine: false,
				user: { ...state.user, firstName: action.data }
			};
		case 'set_last_name':
			return {
				...state,
				pristine: false,
				user: { ...state.user, lastName: action.data }
			};
		case 'set_field':
			return {
				...state,
				pristine: false,
				user: {
					...state.user,
					[action.data.id]: action.data.value
				}
			};
		case 'set_workspace_id': {
			const name = state.workspaces.find((ws) => ws.id === action.data.id).name;
			const scopes = state.user.scopes.map((s, i) =>
				i === action.data.index ? { ...s, id: action.data.id, name } : s
			);
			const scopeSet = new Set(scopes.map((s) => s.id));
			return {
				...state,
				pristine: false,
				availableWorkspaces: state.workspaces.filter((ws) => !scopeSet.has(ws.id)),
				user: {
					...state.user,
					scopes
				}
			};
		}
		case 'set_workspace_role': {
			const scopes = state.user.scopes.map((s, i) =>
				i === action.data.index ? { ...s, role: action.data.role } : s
			);
			return {
				...state,
				pristine: false,
				user: {
					...state.user,
					scopes
				}
			};
		}
		case 'remove_workspace': {
			console.log('remove', action.data);
			const scopes = state.user.scopes.filter((s, index) => !(index === action.data));
			console.log(scopes);
			const scopeSet = new Set(scopes.map((s) => s.id));
			return {
				...state,
				pristine: false,
				availableWorkspaces: state.workspaces.filter((ws) => !scopeSet.has(ws.id)),
				user: {
					...state.user,
					scopes
				}
			};
		}
		case 'add_workspace': {
			const nextAvailableWorkspace = state.availableWorkspaces[0];
			if (!nextAvailableWorkspace) {
				return state;
			}
			const scopes = [
				...state.user.scopes,
				{ id: nextAvailableWorkspace.id, name: nextAvailableWorkspace.name, role: 'developer' }
			];
			const scopeSet = new Set(scopes.map((s) => s.id));
			return {
				...state,
				pristine: false,
				availableWorkspaces: state.workspaces.filter((ws) => !scopeSet.has(ws.id)),
				user: {
					...state.user,
					scopes
				}
			};
		}
		case 'set_admin': {
			return {
				...state,
				pristine: false,
				user: {
					...state.user,
					admin: action.data
				}
			};
		}
		case 'save':
			return {
				...state,
				savePending: true
			};
		case 'saving_error':
			return {
				...state,
				savePending: false,
				pristine: true,
				errors: action.data
			};
		case 'saving_success':
			return {
				...state,
				savePending: false,
				saved: true,
				errors: {}
			};
		case 'reset_save_success':
			return {
				...state,
				saved: false,
				pristine: true,
				errors: {}
			};
		case 'set_password_1':
			return {
				...state,
				password: { ...state.password, newPassword: action.data, validationPending: true }
			};
		case 'set_password_2':
			return {
				...state,
				password: {
					...state.password,
					confirmation: action.data,
					confirmationPristine: false,
					validationPending: true
				}
			};
		case 'edit_password':
			return {
				...state,
				password: {
					newPassword: '',
					confirmation: '',
					confirmationPristine: true,
					validationPending: true,
					errors: {},
					saved: false,
					savePending: false
				}
			};
		case 'save_password':
			return { ...state, password: { ...state.password, savePending: true } };
		case 'saving_success_password':
			return { ...state, password: { ...state.password, savePending: false, saved: true, errors: {} } };
		case 'saving_error_password':
			return { ...state, password: { ...state.password, savePending: false, errors: action.data } };

		case 'check_passwords':
			return {
				...state,
				password: {
					...state.password,
					validationPending: false,
					errors: {
						password:
							!state.password.confirmationPristine &&
							state.password.newPassword &&
							state.password.newPassword !== state.password.confirmation
								? 'PASSWORD_DONT_MATCH'
								: undefined
					}
				}
			};
		case 'finish_password':
			return {
				...state,
				password: false
			};
		default:
			throw new Error(`Unkown action '${action.type}'`);
	}
};

export const UpdateUserView = withUser(({ user }) => ({ rights: user.rights }))((props) => {
	const { userId, rights } = props;
	const { status, result } = useQuery(QUERY, { id: userId });
	const loading = status === 'loading';
	const [state, dispatch] = useReducer(updateUserReducer, {
		pristine: true,
		user: {
			scopes: []
		},
		originalUser: {},
		errors: {},
		password: false,
		savePending: false,
		saved: false
	});

	useEffect(() => {
		if (status === 'done' && result.user) {
			const { id, ...user } = result.user;
			dispatch({
				type: 'init',
				data: {
					userId: id,
					user,
					workspaces: result.workspaces,
					roles: result.roles.map((r) => ({ id: r, name: r }))
				}
			});
		}
	}, [status, result]);

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			if (state.password) {
				dispatch({ type: 'check_passwords' });
			}
		}, 500);
		return () => {
			clearTimeout(timeoutId);
		};
	}, [state.password.newPassword, state.password.confirmation]);

	useEffect(() => {
		if (state.saved) {
			setTimeout(() => {
				dispatch({ type: 'reset_save_success' });
			}, 500);
		}
	}, [state.saved]);

	useEffect(() => {
		if (state.password.saved) {
			setTimeout(() => {
				dispatch({ type: 'finish_password' });
			}, 500);
		}
	}, [state.password.saved]);

	if (status === 'error') {
		return (
			<div
				style={{
					height: '100%',
					padding: '24px',
					backgroundColor: '#EEE',
					boxSizing: 'border-box',
					overflow: 'auto'
				}}
			>
				{result.join('\n')}
			</div>
		);
	}

	const saveUser = async () => {
		dispatch({ type: 'save' });

		try {
			const { provider, ...userToSave } = {
				...state.user,
				scopes: state.user.scopes.map((s) => ({ id: s.id, role: s.role }))
			};
			if (!rights.includes('user.set_admin')) {
				delete userToSave.admin;
			}
			if (!rights.includes('user.set_workspace')) {
				delete userToSave.scopes;
			}
			const {
				updateUser: { success, fieldErrors, code, user }
			} = await runQuery(UPDATE_USER_MUTATION, {
				id: userId,
				user: userToSave
			});
			if (success) {
				dispatch({ type: 'saving_success', data: user });
			} else if (fieldErrors) {
				dispatch({ type: 'saving_error', data: fieldErrors });
			} else {
				dispatch({ type: 'saving_error', data: { form: code } });
			}
		} catch (error) {
			dispatch({ type: 'saving_error', data: { form: 'UNEXPECTED_ERROR' } });
			console.error(error);
		}
	};

	const savePassword = async () => {
		dispatch({ type: 'save_password' });
		try {
			const {
				updateUserPassword: { success, fieldErrors, code, user }
			} = await runQuery(UPDATE_PASSWORD_MUTATION, {
				id: userId,
				newPassword: state.password.newPassword
			});
			if (success) {
				dispatch({ type: 'saving_success_password', data: user });
			} else if (fieldErrors) {
				dispatch({ type: 'saving_error_password', data: fieldErrors });
			} else {
				dispatch({ type: 'saving_error_password', data: { form: code } });
			}
		} catch (error) {
			dispatch({ type: 'saving_error_password', data: { form: 'UNEXPECTED_ERROR' } });
			console.error(error);
		}
	};

	const userForm = () => {
		const hasError = hasFieldError(state.errors);
		const hasRequired = state.user.username && state.user.email;
		const isValid = !!(!hasError && hasRequired);
		return (
			<UpdateUserForm
				user={state.user}
				originalUser={state.originalUser}
				pristine={state.pristine}
				errors={state.errors}
				intl={intl}
				disabled={loading || state.savePending || state.saved}
				valid={isValid}
				showAdmin={rights.includes('user.set_admin') && state.userId !== '00000000000000'}
				showWorkspace={rights.includes('user.set_workspace')}
				onSubmit={saveUser}
				workspaceOptions={state.availableWorkspaces}
				roleOptions={state.roles}
				onUsernameUpdate={(value) => dispatch({ type: 'set_username', data: value })}
				onEmailUpdate={(value) => dispatch({ type: 'set_email', data: value })}
				onFirstNameUpdate={(value) => dispatch({ type: 'set_first_name', data: value })}
				onLastNameUpdate={(value) => dispatch({ type: 'set_last_name', data: value })}
				onChangePassword={() => dispatch({ type: 'edit_password' })}
				onWorkspaceUpdate={(value) => dispatch({ type: 'set_workspace_id', data: value })}
				onRoleUpdate={(value) => dispatch({ type: 'set_workspace_role', data: value })}
				onWorkspaceDelete={(value) => dispatch({ type: 'remove_workspace', data: value })}
				onWorkspaceAdd={() => dispatch({ type: 'add_workspace' })}
				onAdminUpdate={(value) => dispatch({ type: 'set_admin', data: value })}
			/>
		);
	};

	const passwordForm = () => {
		const { newPassword, confirmation, validationPending } = state.password;
		const hasError = hasFieldError(state.password.errors);
		const hasRequired = newPassword && confirmation;
		const isValid = !!(!hasError && hasRequired && !validationPending);
		return (
			<UpdatePasswordForm
				user={state.originalUser}
				pristine={state.password.pristine}
				newPassword={state.password.newPassword}
				passwordConfirmation={state.password.confirmation}
				disabled={state.savePending}
				valid={isValid}
				errors={state.password.errors}
				intl={intl}
				onNewPasswordUpdate={(value) => dispatch({ type: 'set_password_1', data: value })}
				onPasswordConfirmationUpdate={(value) => dispatch({ type: 'set_password_2', data: value })}
				onSubmit={savePassword}
				onCancel={() => dispatch({ type: 'finish_password' })}
			/>
		);
	};

	const showProgress = loading || state.savePending || state.password.savePending;
	const showSuccess = state.saved || state.password.saved;

	return (
		<div
		>
			{state.password ? passwordForm() : userForm()}
			{showProgress && (
				<Overlay>
					<CircularProgress style={{ width: '24px', height: '24px' }} />
				</Overlay>
			)}
			{showSuccess && (
				<Overlay>
					<CheckIcon color="primary" />
				</Overlay>
			)}
		</div>
	);
});

UpdateUserView.propTypes = {
	userId: PropTypes.string.isRequired
};
