import { EnsureUser } from '@cedalo/webui/src/UserProvider';
import React from 'react';
import { Route } from 'react-router-dom';
import { AccountPage } from './accounts';
import { IntegrationsPage } from './integrations';
import SharedMachinePage from './sharemachine/SharedMachinePage';
import { WorkspacePage } from './workspace';

export function getExtensionRoutes() {
	return (
		<React.Fragment>
			<Route path="/shared-machine/:linkId" element={<SharedMachinePage />} />,
			<Route path="accounts">
				<Route
					index
					element={
						<EnsureUser>
							<AccountPage />
						</EnsureUser>
					}
				/>
				<Route
					path=":accountId"
					element={
						<EnsureUser>
							<AccountPage />
						</EnsureUser>
					}
				/>
			</Route>
			<Route path="workspaces">
				<Route
					index
					element={
						<EnsureUser>
							<WorkspacePage />
						</EnsureUser>
					}
				/>
				<Route
					path=":workspaceId"
					element={
						<EnsureUser>
							<WorkspacePage />
						</EnsureUser>
					}
				/>
			</Route>
			<Route path="integrations">
				<Route
					index
					element={
						<EnsureUser>
							<IntegrationsPage />
						</EnsureUser>
					}
				/>
				<Route
					path=":integrationId"
					element={
						<EnsureUser>
							<IntegrationsPage />
						</EnsureUser>
					}
				/>
			</Route>
		</React.Fragment>
	);
}
