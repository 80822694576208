import { intl } from '@cedalo/webui/src/helper/IntlGlobalProvider';
import { runQuery, useQuery } from '@cedalo/webui/src/ui/app/GraphQLWSClient';
import { Overlay } from '@cedalo/webui/src/ui/utils/Overlay';
import { withUser } from '@cedalo/webui/src/UserProvider';
import CheckIcon from '@mui/icons-material/esm/Check';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useEffect, useReducer } from 'react';
import { CreateUserForm } from './CreateUserForm';

const QUERY = `
query UserFormInfo {
	workspaces {
		id
		name
	}

	roles
}
`;

const createUserReducer = (state, action) => {
	switch (action.type) {
		case 'init_ws_roles':
			return {
				...state,
				workspaces: action.data.workspaces,
				roles: action.data.roles,
				availableWorkspaces: action.data.workspaces
			};
		case 'set_username':
			return {
				...state,
				pristine: false,
				user: { ...state.user, username: action.data },
				errors: {
					...state.errors,
					username: !action.data ? 'USERNAME_REQUIRED' : undefined
				}
			};
		case 'set_email':
			return {
				...state,
				pristine: false,
				user: { ...state.user, email: action.data },
				errors: {
					...state.errors,
					email: !action.data ? 'EMAIL_REQUIRED' : undefined
				}
			};
		case 'set_first_name':
			return {
				...state,
				pristine: false,
				user: { ...state.user, firstName: action.data }
			};
		case 'set_last_name':
			return {
				...state,
				pristine: false,
				user: { ...state.user, lastName: action.data }
			};
		case 'set_password':
			return {
				...state,
				pristine: false,
				user: {
					...state.user,
					password: action.data
				}
			};
		// TODO Still used?
		case 'set_field':
			return {
				...state,
				pristine: false,
				user: {
					...state.user,
					[action.data.id]: action.data.value
				}
			};
		case 'set_workspace_id': {
			const name = state.workspaces.find((ws) => ws.id === action.data.id).name;
			const scopes = state.user.scopes.map((s, i) =>
				i === action.data.index ? { ...s, id: action.data.id, name } : s
			);
			const scopeSet = new Set(scopes.map((s) => s.id));
			return {
				...state,
				pristine: false,
				availableWorkspaces: state.workspaces.filter((ws) => !scopeSet.has(ws.id)),
				user: {
					...state.user,
					scopes
				}
			};
		}
		case 'set_workspace_role': {
			const scopes = state.user.scopes.map((s, i) =>
				i === action.data.index ? { ...s, role: action.data.role } : s
			);
			return {
				...state,
				pristine: false,
				user: {
					...state.user,
					scopes
				}
			};
		}
		case 'remove_workspace': {
			console.log('remove', action.data);
			const scopes = state.user.scopes.filter((s, index) => !(index === action.data));
			console.log(scopes);
			const scopeSet = new Set(scopes.map((s) => s.id));
			return {
				...state,
				pristine: false,
				availableWorkspaces: state.workspaces.filter((ws) => !scopeSet.has(ws.id)),
				user: {
					...state.user,
					scopes
				}
			};
		}
		case 'add_workspace': {
			const nextAvailableWorkspace = state.availableWorkspaces[0];
			if (!nextAvailableWorkspace) {
				return state;
			}
			const scopes = [
				...state.user.scopes,
				{ id: nextAvailableWorkspace.id, name: nextAvailableWorkspace.name, role: 'developer' }
			];
			const scopeSet = new Set(scopes.map((s) => s.id));
			return {
				...state,
				pristine: false,
				availableWorkspaces: state.workspaces.filter((ws) => !scopeSet.has(ws.id)),
				user: {
					...state.user,
					scopes
				}
			};
		}
		case 'set_admin': {
			return {
				...state,
				pristine: false,
				user: {
					...state.user,
					admin: action.data
				}
			};
		}
		case 'set_password_confirmation':
			return {
				...state,
				pristine: false,
				passwordConfirmationPristine: false,
				passwordConfirmation: action.data
			};
		case 'check_passwords':
			return {
				...state,
				errors: {
					...state.errors,
					password:
						!state.passwordConfirmationPristine &&
						state.user.password &&
						state.user.password !== state.passwordConfirmation
							? 'PASSWORD_DONT_MATCH'
							: undefined
				}
			};
		case 'save':
			return {
				...state,
				savePending: true
			};
		case 'saving_error':
			return {
				...state,
				savePending: false,
				pristine: true,
				errors: action.data
			};
		case 'saving_success':
			return {
				...state,
				savePending: false,
				savedUser: action.data,
				saved: true
			};
		default:
			throw new Error('Unkown action', action.type);
	}
};

const CREATE_USER_MUTATION = `
mutation CreateUser($user: UserInput!) {
	createUser(user: $user) {
		user {
			id
			username
			email
			firstName
			lastName
			scopes {
				id
			}
		}
		success
		code
		fieldErrors {
			username
			email
			password
		}
	}
}
`;

const hasFieldError = (errors) => Object.entries(errors).filter(([key, value]) => key !== 'form' && !!value).length > 0;

export const CreateUserView = withUser(({ user }) => ({
	rights: user.rights
}))((props) => {
	const { onCancel, onSubmit, rights } = props;
	const { status, result } = useQuery(QUERY);
	const loading = status === 'loading';
	const [state, dispatch] = useReducer(createUserReducer, {
		pristine: true,
		user: {
			username: '',
			email: '',
			firstName: '',
			lastName: '',
			password: '',
			scopes: [],
			admin: false
		},
		errors: {},
		passwordConfirmation: '',
		passwordConfirmationPristine: true,
		savePending: false,
		saved: false
	});

	useEffect(() => {
		if (status === 'done' && result) {
			dispatch({
				type: 'init_ws_roles',
				data: {
					workspaces: result.workspaces,
					roles: result.roles.map((r) => ({ id: r, name: r }))
				}
			});
		}
	}, [status, result]);

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			dispatch({ type: 'check_passwords' });
		}, 500);
		return () => {
			clearTimeout(timeoutId);
		};
	}, [state.passwordConfirmation, state.user.password]);

	useEffect(() => {
		if (state.saved) {
			setTimeout(() => {
				onSubmit(state.user);
			}, 500);
		}
	}, [state.saved]);

	const hasError = hasFieldError(state.errors);
	const hasRequired =
		state.user.username &&
		state.user.email &&
		state.user.password &&
		state.passwordConfirmation &&
		state.user.scopes.every((s) => s.id && s.role);
	const isValid = !!(!hasError && hasRequired);

	const saveUser = async () => {
		dispatch({ type: 'save' });
		try {
			const userToSave = { ...state.user, scopes: state.user.scopes.map((s) => ({ id: s.id, role: s.role })) };
			const {
				createUser: { success, fieldErrors, code, user }
			} = await runQuery(CREATE_USER_MUTATION, { user: userToSave });
			if (success) {
				dispatch({ type: 'saving_success', data: user });
			} else if (fieldErrors) {
				dispatch({ type: 'saving_error', data: fieldErrors });
			} else {
				dispatch({ type: 'saving_error', data: { form: code } });
			}
		} catch (error) {
			dispatch({ type: 'saving_error', data: { form: 'UNEXPECTED_ERROR' } });
			console.error(error);
		}
	};

	const showProgress = state.savePending || loading;

	return (
		<div
		>
			<CreateUserForm
				user={state.user}
				errors={state.errors}
				passwordConfirmation={state.passwordConfirmation}
				pristine={state.pristine}
				valid={isValid}
				disabled={showProgress || state.saved}
				intl={intl}
				onCancel={onCancel}
				onSubmit={saveUser}
				showAdmin={rights.includes('user.set_admin')}
				showWorkspace={rights.includes('user.set_workspace')}
				workspaceOptions={state.availableWorkspaces}
				roleOptions={state.roles}
				onWorkspaceUpdate={(value) => dispatch({ type: 'set_workspace_id', data: value })}
				onRoleUpdate={(value) => dispatch({ type: 'set_workspace_role', data: value })}
				onWorkspaceDelete={(value) => dispatch({ type: 'remove_workspace', data: value })}
				onWorkspaceAdd={() => dispatch({ type: 'add_workspace' })}
				onAdminUpdate={(value) => dispatch({ type: 'set_admin', data: value })}
				onUsernameUpdate={(value) => dispatch({ type: 'set_username', data: value })}
				onEmailUpdate={(value) => dispatch({ type: 'set_email', data: value })}
				onFirstNameUpdate={(value) => dispatch({ type: 'set_first_name', data: value })}
				onLastNameUpdate={(value) => dispatch({ type: 'set_last_name', data: value })}
				onPasswordUpdate={(value) => dispatch({ type: 'set_password', data: value })}
				onPasswordConfirmationUpdate={(value) => dispatch({ type: 'set_password_confirmation', data: value })}
			/>
			{showProgress && (
				<Overlay>
					<CircularProgress style={{ width: '24px', height: '24px' }} />
				</Overlay>
			)}
			{state.saved && (
				<Overlay>
					<CheckIcon color="primary" />
				</Overlay>
			)}
		</div>
	);
});
