import { clearHashProperty, setHash, toHashObject } from './URLHash';

export const parseSidePanelHash = (hash) => {
	const { panel } = toHashObject(hash);
	if (panel) {
		const [, id, data] = panel.split('/');
		return { id, data };
	}
	return { id: null };
};
export const setSidePanelHashString = (panelHashPart) => setHash({ panel: panelHashPart });

export const setSidePanelHash = (id, data) => setSidePanelHashString(`/${[id, data].join('/')}`);

export const clearSidePanelHash = () => clearHashProperty('panel');
