import { intl } from '@cedalo/webui/src/helper/IntlGlobalProvider';
import theme from '@cedalo/webui/src/theme';
import { StreamSheetAppBar } from '@cedalo/webui/src/ui/StreamSheetAppBar';
import StreamSheetNavigation from '@cedalo/webui/src/ui/StreamSheetNavigation';
import CustomTooltip from '@cedalo/webui/src/ui/utils/CustomTooltip';
import StreamSheetBreadCrumbs from '@cedalo/webui/src/ui/utils/StreamSheetBreadCrumbs';
import StreamSheetPaper from '@cedalo/webui/src/ui/utils/StreamSheetPaper';
import SettingsPaper from '@cedalo/webui/src/ui/utils/SettingsPaper';
import { Box, IconButton, SvgIcon, Typography } from '@mui/material';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { AccountDetailView } from './AccountDetailView';
import { AccountsTable } from './AccountsTable';
import { CreateAccountDialog } from './CreateAccountDialog';
import { useNavigate } from 'react-router-dom';

const drawerWidth = 60;

// function SettingsPaper(props) {
// 	return null;
// }
//
// SettingsPaper.propTypes = {
// 	helpContext: PropTypes.string,
// 	title: PropTypes.element,
// 	close: PropTypes.any,
// 	open: PropTypes.func,
// 	children: PropTypes.node
// };

export function AccountsPage({ accountId, delegate, breadcrumbsPath }) {
	const [filter, setFilter] = useState('');
	const [showNewAccountDialog, setShowNewAccountDialog] = useState(false);

	document.title = intl.formatMessage({ id: delegate.getIntlKey('TabTitle.Accounts') }, {});
	const navigate = useNavigate();

	return (
		<div style={{ width: 'inherit', height: 'inherit' }}>
			<div>
				<StreamSheetAppBar
					key="appPageBar"
					helpContext="integrations"
					showFilter
					onFilterChange={(newFilter) => setFilter(newFilter.toLowerCase())}
				/>
				<StreamSheetNavigation openPage={navigate} />
			</div>
			<Box
				sx={{
					position: 'relative',
					height: 'calc(100% - 50px)',
					width: `calc(100% - ${drawerWidth}px)`,
					marginLeft: `${drawerWidth}px`,
					overflow: 'hidden',
					bgcolor: theme.wall.backgroundColor
				}}
			>
				<div
					style={{
						display: 'inline-flex',
						width: 'calc(100% - 15px)',
						justifyContent: 'space-between'
					}}
				>
					<StreamSheetBreadCrumbs path={breadcrumbsPath} openPage={navigate} />
					<div
						style={{
							alignItems: 'end',
							display: 'flex',
							minHeight: '10px',
							marginTop: '5px'
						}}
					>
						<div
							style={{
								display: 'flex'
							}}
						>
							<CustomTooltip
								header={delegate.getIntlKey('Tooltip.Create')}
								message={delegate.getIntlKey('Tooltip.CreateMessage')}
							>
								<div>
									<IconButton
										aria-label="Stop"
										onClick={() => setShowNewAccountDialog(true)}
										size="small"
									>
										<SvgIcon>
											<path d="M20 14H14V20H10V14H4V10H10V4H14V10H20V14Z" />
										</SvgIcon>
									</IconButton>
								</div>
							</CustomTooltip>
							{showNewAccountDialog ? (
								<CreateAccountDialog
									onClose={() => setShowNewAccountDialog(false)}
									onNewAccount={(id) => navigate(delegate.pathForId(id))}
									delegate={delegate}
								/>
							) : null}
						</div>
					</div>
				</div>
				<StreamSheetPaper data-tour="page-integrations">
					<Box
						sx={{
							height: 'calc(100%)',
							width: 'calc(100% - 402px)'
						}}
						data-tour="page-apps"
					>
						<AccountsTable
							accountId={accountId}
							onSelectAccount={(id) => navigate(delegate.pathForId(id))}
							filter={filter}
							title="Integration Accounts"
							subtitle="Create accounts for integration functions to share between multiple apps"
							delegate={delegate}
						/>
					</Box>
					<SettingsPaper
						open
						width="360px"
						helpContext="accountsettings"
						title={<FormattedMessage id="Extensions.Account.Settings" defaultMessage="Account Settings" />}
					>
						{accountId ? (
							<AccountDetailView accountId={accountId} delegate={delegate} />
						) : (
							<Box sx={{ p: 3, display: 'flex', justifyContent: 'center' }}>
								<Typography color="text.primary" variant="h5">
									No account selected
								</Typography>
							</Box>
						)}
					</SettingsPaper>
				</StreamSheetPaper>
			</Box>
		</div>
	);
}
