import { IconUser } from '@cedalo/webui/src/components/icons';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { userShape } from './UserPropTypes';
import { UserWorkspaceTable } from './UserWorkspaceTable';

export const UpdateUserForm = (props) => {
	const {
		originalUser,
		errors,
		user,
		valid,
		disabled,
		pristine,
		intl,
		onUsernameUpdate,
		onEmailUpdate,
		onFirstNameUpdate,
		onLastNameUpdate,
		onWorkspaceUpdate,
		onRoleUpdate,
		onWorkspaceDelete,
		onWorkspaceAdd,
		onAdminUpdate,
		workspaceOptions,
		roleOptions,
		showWorkspace,
		showAdmin,
		onCancel,
		onSubmit,
		onChangePassword
	} = props;

	const errorsMappings = {
		EMAIL_INVALID: intl.formatMessage({ id: 'Admin.User.errorEMailInvalid' }, {}),
		EMAIL_IN_USE: intl.formatMessage({ id: 'Admin.User.errorEMailInUse' }, {}),
		EMAIL_REQUIRED: intl.formatMessage({ id: 'Admin.User.errorEMailRequired' }, {}),
		USERNAME_REQUIRED: intl.formatMessage({ id: 'Admin.User.errorUsernameRequired' }, {}),
		USERNAME_IN_USE: intl.formatMessage({ id: 'Admin.User.errorUsernameInUse' }, {}),
		USERNAME_INVALID: intl.formatMessage({ id: 'Admin.User.errorUsernameInvalid' }, {}),
		UNEXPECTED_ERROR: intl.formatMessage({ id: 'Admin.User.errorUnexpected' }, {}),
		PASSWORD_DONT_MATCH: intl.formatMessage({ id: 'Admin.User.errorPasswordsDontMatch' }, {})
	};

	const getError = (code) => (code ? errorsMappings[code] || errorsMappings.UNEXPECTED_ERROR : undefined);
	const isLocalProvider = user ? user.provider === 'local' : false;

	return (
		<form>
			<Grid container spacing={2}>
				<Grid item container spacing={1} justifyContent="space-between">
					<Grid item />
					{errors.form ? (
						<Grid item>
							<Typography color="error" variant="subtitle1">
								{getError(errors.form)}
							</Typography>
						</Grid>
					) : null}
					<Grid item>
						<Chip icon={<IconUser />} label={originalUser.username} />
					</Grid>
				</Grid>
				<Grid item xs={12} sm={12}>
					<TextField
						required
						variant="outlined"
						size="small"
						id="username"
						label={<FormattedMessage id="Admin.User.labelUsername" defaultMessage="Username" />}
						fullWidth
						error={!!errors.username}
						helperText={getError(errors.username)}
						disabled={disabled}
						value={user.username || ''}
						onChange={(event) => onUsernameUpdate(event.target.value)}
					/>
				</Grid>
				<Grid item xs={12} sm={12}>
					<TextField
						required
						variant="outlined"
						size="small"
						id="email"
						label={<FormattedMessage id="Admin.User.labelEMail" defaultMessage="E-Mail" />}
						fullWidth
						error={!!errors.email}
						helperText={getError(errors.email)}
						disabled={disabled || !isLocalProvider}
						value={user.email || ''}
						onChange={(event) => onEmailUpdate(event.target.value)}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						id="firstName"
						variant="outlined"
						size="small"
						label={<FormattedMessage id="Admin.User.labelFirstName" defaultMessage="First name" />}
						fullWidth
						disabled={disabled}
						value={props.user.firstName || ''}
						onChange={(event) => onFirstNameUpdate(event.target.value)}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						id="lastName"
						variant="outlined"
						size="small"
						label={<FormattedMessage id="Admin.User.labelLastName" defaultMessage="Last name" />}
						fullWidth
						disabled={disabled}
						value={props.user.lastName || ''}
						onChange={(event) => onLastNameUpdate(event.target.value)}
					/>
				</Grid>
				{showAdmin ? (
					<Grid item xs={12}>
						<FormControl>
							<FormControlLabel
								control={
									<Checkbox
										checked={user.admin || false}
										disabled={disabled}
										onChange={(event) => onAdminUpdate(event.target.checked)}
										value="administrator"
									/>
								}
								label={<FormattedMessage id="Admin.User.admin" defaultMessage="Administrator" />}
							/>
							<FormHelperText>
								<FormattedMessage
									id="Admin.User.admin.helper"
									defaultMessage="Give the user full administrative rights"
								/>
							</FormHelperText>
						</FormControl>
					</Grid>
				) : null}
				{!user.admin && showWorkspace ? (
					<Grid item container xs={12}>
						<Grid container item alignItems="center" justifyContent="space-between">
							<Grid item>
								<InputLabel htmlFor="workspaces">
									<FormattedMessage id="Admin.User.workspaces" defaultMessage="Workspaces" />
								</InputLabel>
							</Grid>
							<Grid>
								<Button variant="outlined" disabled={disabled} onClick={() => onWorkspaceAdd()}>
									<FormattedMessage id="Admin.User.workspaceAdd" defaultMessage="Add to Workspace" />
								</Button>
							</Grid>
						</Grid>
						{user.scopes.length > 0 ? (
							<Paper variant="outlined" style={{ marginTop: '10px', width: '100%' }}>
								<div style={{ margin: '10px' }}>
									<UserWorkspaceTable
										workspaces={user.scopes}
										onWorkspaceUpdate={onWorkspaceUpdate}
										onRoleUpdate={onRoleUpdate}
										onWorkspaceDelete={onWorkspaceDelete}
										disabled={disabled}
										workspaceOptions={workspaceOptions}
										roleOptions={roleOptions}
									/>
								</div>
							</Paper>
						) : (
							<div style={{ margin: '16px 0px 0px', width: '100%' }}>
								<Typography color="text.primary" variant="body2" algin="center">
									<FormattedMessage
										id="Admin.User.noWorkspaceAssigned"
										defaultMessage="Currently no workspace assigned. The user will not be abel to login. Please add the user to a workspace."
									/>
								</Typography>
							</div>
						)}
					</Grid>
				) : null}
				{isLocalProvider ? (
					<Grid item container xs={12} spacing={2} justifyContent="space-between">
						<Grid item xs={12}>
							<FormLabel>
								<FormattedMessage id="Admin.User.password" defaultMessage="Password" />
							</FormLabel>
						</Grid>
						<Grid item xs={12}>
							<Button variant="outlined" onClick={onChangePassword} disabled={disabled}>
								<FormattedMessage
									id="Admin.User.buttonChangePassword"
									defaultMessage="Change password"
								/>
							</Button>
						</Grid>
					</Grid>
				) : null}

				<Grid container item spacing={2} xs={12} justifyContent="flex-end" direction="row">
					{onCancel ? (
						<Grid item>
							<Button variant="outlined" onClick={onCancel} disabled={disabled}>
								<FormattedMessage id="Admin.User.buttonCancel" defaultMessage="Cancel" />
							</Button>
						</Grid>
					) : null}
					<Grid item>
						<Button
							// className={classes.button}
							variant="outlined"
							onClick={onSubmit}
							disabled={pristine || disabled || !valid}
						>
							<FormattedMessage id="Admin.User.buttonSave" defaultMessage="Save" />
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</form>
	);
};

UpdateUserForm.propTypes = {
	user: userShape.isRequired,
	errors: PropTypes.shape({
		username: PropTypes.string,
		email: PropTypes.string
	}).isRequired,
	originalUser: userShape.isRequired,
	pristine: PropTypes.bool.isRequired,
	valid: PropTypes.bool.isRequired,
	disabled: PropTypes.bool.isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired,
	onUsernameUpdate: PropTypes.func.isRequired,
	onEmailUpdate: PropTypes.func.isRequired,
	onFirstNameUpdate: PropTypes.func.isRequired,
	onLastNameUpdate: PropTypes.func.isRequired,
	onChangePassword: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
	onCancel: PropTypes.func
};

UpdateUserForm.defaultProps = {
	onCancel: null
};
