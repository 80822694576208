export default [
	{
		selector: '[data-tour="page-home"]',
		content: 'The tour starts at the home screen. Here you can start favorite apps, view the documentation ...',
		routing: '/home',
	},
	{
		selector: '[data-tour="breadcrumbs"]',
		content: 'The navigation line display your location. It can also be used to navigate to top level pages',
	},
	{
		selector: '[data-tour="step-nav-bar"]',
		content: 'The navigation bar allows you to jump to the views of StreamSheets containing overviews of Apps and Configurations and to export or manage StreamSheets',
	},
	{
		selector: '[data-tour="step-nav-bar-home"]',
		content: 'The home screen provides shortcuts to....'
	},
	{
		selector: '[data-tour="step-nav-bar-apps"]',
		content: 'The Apps overview an overview of all Apps. The overview can be filtered and grouped by Workspace.'
	},
	{
		selector: '[data-tour="step-nav-bar-accounts"]',
		content: 'The Account overview shows a list of all Account allowing you to access a stream or data source.'
	},
	{
		selector: '[data-tour="step-nav-bar-export"]',
		content: 'Here you can export existing Apps to transfer them to another StreamSheets installation.'
	},
	{
		selector: '[data-tour="step-nav-bar-admin"]',
		content: 'Choose this section to edit users, workspaces and general settigns.'
	},
	{
		selector: '[data-tour="step-nav-bar-apps"]',
		content: 'Now lets continue to the apps overview',
		routing: '/apps'
	},
	{
		selector: '[data-tour="page-apps"]',
		content: 'The table shows all Apps currently available',
	},
	{
		selector: '[data-tour="apps-workspace"]',
		content: 'You can filter or group apps by workspace',
	},
	{
		selector: '[data-tour="apps-filter"]',
		content: 'You can filter apps also by a search expression',
	},
	{
		selector: '[data-tour="apps-sort"]',
		content: 'You can sort apps by name or modification date',
	},
	{
		selector: '[data-tour="apps-add"]',
		content: 'You create a new app, clicking on the Add Icon',
	},
	{
		selector: '[data-tour="apps-open"]',
		content: 'You create open an app by clicking on it',
	},
	{
		selector: '[data-tour="apps-actions"]',
		content: 'You create run and stop apps here',
	},
	{
		selector: '[data-tour="step-nav-bar-accounts"]',
		content: 'Now lets continue to the accounts overview',
		routing: '/accounts'
	},
	{
		selector: '[data-tour="page-accounts"]',
		content: 'This view lists all accounts. Accounts define general settings to access a stream or another data source',
	},
	{
		selector: '[data-tour="nav-bar-app"]',
		content: 'Now lets open an App',
		routing: '/apps/B1Z6-2p4ec#'
	},
	{
		selector: '[data-tour="page-app"]',
		content: 'Keep on explaining...',
	},
	{
		selector: '[data-tour="settings-inbox"]',
		content: 'Here you can define the inbox settings...',
		routing: '/apps/B1Z6-2p4ec#panel=/InboxSettings/'
	},
];
