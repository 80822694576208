import * as Actions from '@cedalo/webui/src/actions/actions';
import { withAppData } from '@cedalo/webui/src/ui/app/AppProvider';
import { useLocalState } from '@cedalo/webui/src/ui/app/GraphQLWSClient';
import SettingsPaper from '@cedalo/webui/src/ui/utils/SettingsPaper';
import { Checkbox, FormControl, FormControlLabel, FormGroup } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ ...Actions }, dispatch);
}

export const OpcuaServerSettings = connect(
	null,
	mapDispatchToProps
)(
	withAppData(({ machine }) => ({
		machineId: machine.id,
		isOPCUA: machine.settings.isOPCUA
	}))(({ machineId, open, onClose, isOPCUA, updateMachineSettings }) => {
		const [currentIsOpcua, setLocal] = useLocalState(isOPCUA);

		return (
			<SettingsPaper
				open={open}
				close={onClose}
				helpContext="opcuaserver"
				title={
					<FormattedMessage
						id="Extensions.OpcuaServerSettings.Title"
						defaultMessage="OPCUA Server Settings"
					/>
				}
			>
				<FormGroup>
					<FormControl margin="dense">
						<FormControlLabel
							control={
								<Checkbox
									color="primary"
									checked={currentIsOpcua}
									onChange={(ev, checked) => {
										setLocal(checked);
										updateMachineSettings(machineId, { isOPCUA: checked });
									}}
								/>
							}
							label={
								<FormattedMessage
									id="Extensions.OpcuaServerSettings.ExposeViaOpcua"
									defaultMessage="Expose this App via OPCUA"
								/>
							}
						/>
					</FormControl>
				</FormGroup>
			</SettingsPaper>
		);
	})
);
