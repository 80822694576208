import { GatewayMessagingProtocol } from '@cedalo/protocols';
import qs from 'query-string';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { accessManager } from './helper/AccessManager';
import ConfigManager from './helper/ConfigManager';
import gatewayClient from './helper/GatewayClient';
import ME_QUERY from './me.gql';
import ME_CHANGES_SUBSCRIPTION from './me-changes.gql';
import APPS_CHANGES_SUBSCRIPTION from './ui/app/app-changes-minimal.gql';
import LRU_APP_SUBSCRIPTION from './lruapps-changes.gql';
import { runQuery, useSubscription } from './ui/app/GraphQLWSClient';
import { ServerStatusDialog } from './ui/utils/ServerStatusDialog';

import { Navigate } from 'react-router-dom';
import { useLocale } from './languages/LocaleProvider';

const CONFIG = ConfigManager.config.gatewayClientConfig;
const { EVENTS } = GatewayMessagingProtocol;

export const UserContext = React.createContext(null);
const DAY_IN_MS = 24 * 60 * 60 * 1000;
const msToDays = (ms) => ms / DAY_IN_MS;
const expiresInDays = (validUntil) => Math.ceil(msToDays(validUntil - Date.now()));
const createLicenseInfo = (licenseInfo: any = {}, errcode?: string) => {
	// TODO: extract every information from license object?
	const {
		edition = 'Open Source',
		service,
		valid,
		validUntil,
		issuedBy,
		issuedTo,
		maxInstallations,
		maxStreamsheets,
		usedStreamsheets
	} = licenseInfo;
	const info: any = {
		edition,
		service,
		issuedBy,
		issuedTo,
		maxInstallations,
		maxStreamsheets,
		usedStreamsheets,
		isInvalid: !valid,
		errorCode: errcode
	};
	if (validUntil != null) info.daysLeft = expiresInDays(validUntil);
	return info;
};
const getError = ({ data = {} }) => {
	const { machineserver = {} } = data;
	return machineserver.error || {};
};
const handleLicenseError = (error, state) => {
	const { type, code, info } = error;
	return type === 'LicenseError' ? { ...state, licenseInfo: createLicenseInfo(info, code) } : state;
};

// const localeFromUser = (user) => user && user.settings && user.settings.locale;
const getMetaInformation = async (setMetaInfo) => {
	try {
		const metaInformation = await gatewayClient.getMetaInformation();
		setMetaInfo(metaInformation);
	} catch (error) {
		if (error.status === 401) {
			// TODO: This is currently the first request that is executed, so only checking here for now works
			console.log('Invalid session. Redirecting to /logout');
			accessManager.logoutUI(true);
		} else {
			console.error(error);
		}
	}
};

export const UserProvider = (props) => {
	const [user, setUser] = useState(null);
	const [licenseInfo, setLicenseInfo] = useState<any>({});
	const [loadUser, setLoadUser] = useState(false);
	const [machines, setMachines] = useState(null);
	const [isConnected, setConnected] = useState(false);
	const [reconnectCountDown, setReconnectCountDown] = useState(-1);
	const [reconnecting, setReconnecting] = useState(false);
	const [metaInfo, _setMetaInfo] = useState(null);
	const { setLocale } = useLocale();
	const { token, userId } = qs.parse(location.search);
	if (token && !accessManager.authToken) {
		accessManager.loginWithToken({
			token,
			user: { username: userId }
		});
	}

	const setMetaInfo = (metaInfo) => {
		_setMetaInfo(metaInfo);
		setLicenseInfo(createLicenseInfo(metaInfo.licenseInfo));
	};

	useEffect(() => {
		if (reconnecting) {
			setReconnectCountDown(5);
			const intervalId = setInterval(() => {
				setReconnectCountDown((c) => (c - 1 + 6) % 6);
			}, 1000);
			return () => clearInterval(intervalId);
		}
	}, [reconnecting]);

	useEffect(() => {
		if (reconnecting && reconnectCountDown === 0) {
			const run = async () => {
				setReconnecting(false);
				try {
					await gatewayClient.connect(CONFIG);
					window.location.reload();
				} catch (error) {
					console.log(error);
					setReconnecting(true);
				}
			};
			run();
		}
	}, [reconnectCountDown]);

	useEffect(() => {
		const connect = async () => {
			// return null;
			gatewayClient.on('service', (/* event */) => {
				getMetaInformation(setMetaInfo);
			});
			gatewayClient.on('redirect', () => {
				accessManager.logoutUI(true);
			});
			const config = {
				...CONFIG,
				pathname: window.location.pathname,
				token: accessManager.authToken
			};
			await gatewayClient.connect(config);

			getMetaInformation(setMetaInfo);
			gatewayClient.on(EVENTS.SESSION_INIT_EVENT, (event) => {
				sessionStorage.setItem('sessionId', event.session.id);
			});
			gatewayClient.on(EVENTS.GATEWAY_DISCONNECTED_EVENT, (event) => {
				getMetaInformation(setMetaInfo);
				setReconnecting(true);
				setConnected(false);
			});
			gatewayClient.on(EVENTS.SERVICE_DISCONNECTED_EVENT, (event) => {
				setConnected(false);
				getMetaInformation(setMetaInfo);
			});
			gatewayClient.on(EVENTS.SERVICE_CONNECTED_EVENT, (event) => {
				setConnected(true);
				getMetaInformation(setMetaInfo);
			});
			gatewayClient.on(EVENTS.LICENSE_INFO_EVENT, (event) => {
				setLicenseInfo(createLicenseInfo(event.licenseInfo));
			});
			setConnected(true);
		};
		if (loadUser) {
			connect();
		}
	}, [loadUser]);
	useEffect(() => {
		const run = async () => {
			const result = await runQuery(ME_QUERY);
			const user = result.me;
			const { machines } = result;
			const { id, displayName, settings } = user;
			// const urlHash = qs.parse(window.location.hash);
			// const currentScope = urlHash.scope;
			// TODO: setup locale
			// localStorage.setItem('user', JSON.stringify({ id, displayName, settings }));
			setMachines(machines);
			setUser(user);
			const locale = user?.settings?.locale;
			if (locale) setLocale(locale);
		};
		if (loadUser) {
			run();
		}
	}, [loadUser]);

	const [meChangesSubData, subError] = useSubscription(ME_CHANGES_SUBSCRIPTION);
	useEffect(() => {
		if (user && meChangesSubData) {
			const eventData = meChangesSubData.meChanged;
			switch (eventData.__typename) {
				case 'UserDeleteEvent': {
					// Do nothing
					break;
				}
				case 'UserUpdateEvent': {
					setUser((current) => ({ ...current, ...eventData.userUpdate }));
					break;
				}
				default:
			}
		}
	}, [meChangesSubData]);

	const [lruAppsChangesSubData, lruAppsChangesSubError] = useSubscription(LRU_APP_SUBSCRIPTION);

	useEffect(() => {
		const data = lruAppsChangesSubData && lruAppsChangesSubData.lruAppsChanged;
		if (data) {
			setUser((current) => ({ ...current, lruApps: data.apps }));
		}
	}, [lruAppsChangesSubData]);

	useEffect(() => {
		if (lruAppsChangesSubError) {
			console.error(lruAppsChangesSubError);
		}
	}, lruAppsChangesSubError);

	const [appsChangesSubData] = useSubscription(APPS_CHANGES_SUBSCRIPTION);
	useEffect(() => {
		if (appsChangesSubData && machines) {
			const eventData = appsChangesSubData.appChanged;
			switch (eventData.__typename) {
				case 'AppCreateEvent': {
					setMachines((current) => [...current, eventData.app]);
					break;
				}
				case 'AppDeleteEvent': {
					setMachines((current) => current.filter((app) => app.id !== eventData.appId));
					break;
				}
				case 'AppUpdateEvent': {
					const updateName = eventData.name ? (name) => eventData.name : (name) => name;
					const updateSettings =
						eventData.image !== null ? (settings) => ({ ...settings, image: eventData.image }) : (s) => s;
					setMachines((current) =>
						current.map((app) =>
							app.id === eventData.appId
								? { ...app, name: updateName(app.name), settings: updateSettings(app.settings) }
								: app
						)
					);
					break;
				}
				default:
			}
		}
	}, [appsChangesSubData]);

	return (
		<UserContext.Provider
			value={{
				user,
				machines,
				licenseInfo,
				metaInfo,
				isConnected,
				clearLicenseError: () => setLicenseInfo(({ errorCode, ...info }) => info),
				loadUser: () => setLoadUser(true),
				spinner: <ServerStatusDialog open />
			}}
		>
			{props.children}
		</UserContext.Provider>
	);
};

const selectProps = (selector, props, previous, ownProps) => {
	const newSelection = selector(props, ownProps);
	const newEntries = Object.entries(newSelection);
	return newEntries.length === Object.keys(previous).length && newEntries.every(([k, v]) => v === previous[k])
		? previous
		: newSelection;
};

const UserConsumerWrapper = ({ ownProps, userData, selector, Component }) => {
	const previous = useRef({});
	const selectedProps = selectProps(selector, userData, previous.current, ownProps);
	previous.current = selectedProps;
	return useMemo(() => <Component {...ownProps} {...previous.current} />, [ownProps, previous.current]);
};

const UserLoader = (props) => {
	useEffect(() => {
		props.loadUser();
	}, []);
	return null;
};

export const EnsureUser = (props) => (
	<UserContext.Consumer>
		{(userData) => {
			const hasAuthToken = !!accessManager.authToken;
			if (!hasAuthToken) {
				const redirect = new URL(window.location as any as URL);
				const token = redirect.searchParams.get('token');
				const userId = redirect.searchParams.get('userId');
				redirect.searchParams.delete('token');
				redirect.searchParams.delete('userId');
				const searchParams = new URLSearchParams();
				if (token && userId) {
					searchParams.append('token', token);
					searchParams.append('userId', userId);
				}
				if (!redirect.pathname.startsWith('/login')) {
					searchParams.append('redirect', encodeURIComponent(redirect.toString()));
				}
				return <Navigate to={`/login?${searchParams.toString()}`} />;
			}
			return !userData.user || !userData.metaInfo ? <UserLoader {...userData} /> : props.children;
		}}
	</UserContext.Consumer>
);

export const withUser =
	(selector = (x) => {}) =>
	(Component, Fallback?) =>
	(props) =>
		(
			<UserContext.Consumer>
				{(userData) => {
					if (!userData.user || !userData.metaInfo) {
						return Fallback ? <Fallback {...props} /> : userData.spinner;
					}
					return (
						<UserConsumerWrapper
							ownProps={props}
							selector={selector}
							userData={userData}
							Component={Component}
						/>
					);
				}}
			</UserContext.Consumer>
		);
