import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DeleteIcon from '@mui/icons-material/esm/Delete';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const UserWorkspaceTable = (props) => {
	const {
		disabled,
		workspaces,
		workspaceOptions,
		onWorkspaceUpdate,
		onRoleUpdate,
		onWorkspaceDelete,
		roleOptions
	} = props;
	return (
        <Table size="small">
			<TableHead>
				<TableRow>
					<TableCell padding="none" style={{ width: '42%' }}>
						<FormattedMessage id="Admin.User.workspace" defaultMessage="Workspace" />
					</TableCell>
					<TableCell style={{ width: '42%' }}>
						<FormattedMessage id="Admin.User.role" defaultMessage="Role" />
					</TableCell>
					<TableCell padding="none" style={{ width: '16%' }} />
				</TableRow>
			</TableHead>
			<TableBody>
				{workspaces.map((scope, index) => (
					// eslint-disable-next-line
					<TableRow key={index} style={{ height: '45px'}}>
						<TableCell padding="none" size="small">
							<Select
								id={`workspaces-workspace-${index}`}
								disabled={disabled}
								value={scope.id}
								fullWidth
								size="small"
								sx={{maxWidth: '160px'}}
								onChange={(event) => onWorkspaceUpdate({ index, id: event.target.value })}
							>
								{[...workspaceOptions, scope]
									.sort((a, b) => (a.name < b.name ? -1 : 1))
									.map((value) => (
										<MenuItem key={value.id} value={value.id}>
											{value.name}
										</MenuItem>
									))}
							</Select>
						</TableCell>
						<TableCell size="small">
							<Select
								id={`workspaces-role-${index}`}
								disabled={disabled}
								value={scope.role}
								size="small"
								fullWidth
								sx={{maxWidth: '120px'}}
								onChange={(event) => onRoleUpdate({ index, role: event.target.value })}
							>
								{roleOptions.map((value) => (
									<MenuItem key={value.id} value={value.id}>
										{value.name}
									</MenuItem>
								))}
							</Select>
						</TableCell>
						<TableCell padding="none" size="small">
							<IconButton
                                style={{ color: '#FF0022', float: 'right' }}
                                onClick={(event) => {
									event.stopPropagation();
									onWorkspaceDelete(index);
								}}
                                size="large">
								<DeleteIcon />
							</IconButton>
						</TableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>
    );
};
