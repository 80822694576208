// import {AppPage} from '@cedalo/webui/src/ui/pages/AppPage';
import SheetComponent from '@cedalo/webui/src/ui/sheeteditor/SheetComponent';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { setAppState } from '@cedalo/webui/src/actions/actions';
import { pathLogin, pathLogout } from '@cedalo/webui/src/actions/UserActions';
import gatewayClient from '@cedalo/webui/src/helper/GatewayClient';
import { intl } from '@cedalo/webui/src/helper/IntlGlobalProvider';
import ErrorPage from './ErrorPage';
import ConfigManager from '@cedalo/webui/src/helper/ConfigManager';
import { accessManager } from '@cedalo/webui/src/helper/AccessManager';
import { EnsureUser } from '@cedalo/webui/src/UserProvider';
import { AppProvider } from '@cedalo/webui/src/ui/app/AppProvider';
import { useLocation, useParams } from 'react-router-dom';

const CONFIG = ConfigManager.config.gatewayClientConfig;

const SHARED_MACHINE = `
query SharedMachine($id: ID!) {
	sharedMachine(id: $id) {
		code,
		success,
		link {
			machineId
		}
	}
}
`;

const SharedPageWrapper = (props) => {
	return (
		<AppProvider {...props} machineId={props.machineId} noEdit>
			<div
				style={{
					height: `calc(100%)`,
					position: 'relative'
				}}
			>
				<SheetComponent
					appId={props.machineId}
					splitView={false}
					mediaWidth="large"
					showViewMode
					// showDialog={(id) => onShowDialog(id)}
					// onSheetActivate={(id) => setActiveSheetId(id)}
					canEditMachine={false}
				/>
			</div>
			{/* <AppPage {...props} /> */}
		</AppProvider>
	);
};

const SharedMachineError = {
	from: (code) => {
		let message;
		let title;
		switch (code) {
			case 'LINK_EXPIRED':
			case 'LINK_INVALID':
			case 'LINK_NOT_AVAILABLE':
			case 'MACHINE_NOT_AVAILABLE':
				title = '404';
				message = intl.formatMessage({ id: 'Extensions.SharedMachinePage.Error.LinkInvalid' });
				break;
			default:
				message = 'Login failed! Link is invalid.';
		}
		console.log(SharedMachineError.create(message, { title }));
		return SharedMachineError.create(message, { title });
	},
	create: (message, other) => Object.assign(new Error(message), other)
};

const SharedMachinePage = (props) => {
	const location = useLocation();
	const { linkId } = useParams();
	const [error, setError] = useState();
	const [machineId, setMachineId] = useState('');

	const setup = async () => {
		const alreadyLoggedIn = !!accessManager.authToken;
		let connected = false;
		try {
			if (alreadyLoggedIn) {
				await gatewayClient.connect(CONFIG);
				connected = true;
			} else {
				const resp = await props.pathLogin(location.pathname);
				if (resp.error && resp.error === 'LOGIN_REQUIRED') {
					window.location.href = `${window.location.origin}/login?redirect=${encodeURIComponent(
						props.location.pathname + props.location.search + props.location.hash
					)}`;
					return;
				}
				connected = !resp.error && !!resp.token;
			}
			if (!connected) throw SharedMachineError.from('LINK_INVALID');
			const { sharedMachine } = await gatewayClient.graphql(SHARED_MACHINE, { id: linkId });
			const { success, code, link } = sharedMachine;
			if (!success) throw SharedMachineError.from(code);
			setMachineId(link.machineId);
		} catch (err) {
			setError(err);
			// ensure we are connected or calling logout fails due to missing socket connection...
			if (connected && !alreadyLoggedIn) props.pathLogout();
		}
	};
	useEffect(() => {
		setup();
	}, []);

	// eslint-disable-next-line no-nested-ternary
	return machineId ? (
		<EnsureUser>
			<SharedPageWrapper {...props} machineId={machineId} />
		</EnsureUser>
	) : error ? (
		<ErrorPage title={error.title} message={error.message} />
	) : null;
};

const mapDispatchToProps = { pathLogin, pathLogout, setAppState };

export default connect(undefined, mapDispatchToProps)(SharedMachinePage);
