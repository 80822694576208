import { withAppData } from '@cedalo/webui/src/ui/app/AppProvider';
import { runQuery, useQuery } from '@cedalo/webui/src/ui/app/GraphQLWSClient';
import SettingsPaper from '@cedalo/webui/src/ui/utils/SettingsPaper';
import {
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormHelperText,
	MenuItem,
	TextField,
	Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

// const GATEWAY_CONFIG = ConfigManager.config.gatewayClientConfig;

const HTTP_SERVER_SETTINGS_QUERY = `
	query HttpSererSettings($machineId: ID!) {
		scopedByMachine(machineId: $machineId) {
			machine(id: $machineId) {
				httpServer {
					enabled
					streamsheetId
					expectResponse
					path
					auth {
						username
						password
					}
				}
				streamsheets {
					id
					name
				}
			}
		}
	}
`;

const HTTP_SERVER_SETTINGS_MUTATION = `
	mutation UpdateHttpServerSettings($machineId: ID!, $httpServer: HttpServerInput!) {
		scopedByMachine(machineId: $machineId) {
			updateMachineHttpServer(machineId: $machineId, httpServer: $httpServer) {
				success
				code
				httpServer {
					enabled
					streamsheetId
					expectResponse
					path
					auth {
						username
						password
					}
				}
			}
		}
	}
`;

const getHostOrigin = () => {
	if (!window.location) throw new Error('Failed to determine host origin!');
	return window.location.origin;
};

const doUpdateHttpServer = async (machineId, httpServerUpdate) => {
	try {
		const data = await runQuery(HTTP_SERVER_SETTINGS_MUTATION, {
			machineId,
			httpServer: httpServerUpdate
		});
		const { success, code, httpServer } = data.scopedByMachine.updateMachineHttpServer;
		if (!success) {
			throw code;
		}
		return { success, httpServer };
	} catch (error) {
		console.error(`Failed to update HTTP server settings: ${httpServerUpdate}`, error);
		return { success: false };
	}
};

export const HttpServerSettings = withAppData(({ machine }) => ({ machineId: machine.id }))(
	({ machineId, open, onClose }) => {
		const [httpServer, setHttpServer] = useState(null);
		const [streamsheets, setStreamsheets] = useState(null);
		// // const [requestState, setRequestState] = useState('not-fetched');
		// // const { data, loading, errors } = useGraphQL(
		const settingsQuery = useQuery(HTTP_SERVER_SETTINGS_QUERY, { machineId });

		const updateHttpServer = async (update = {}) => {
			// setRequestState('fetchingw');
			const updated = { ...httpServer, ...update };
			setHttpServer(updated);
			const { path, requireAuth, ...httpServerUpdate } = updated;
			await doUpdateHttpServer(machineId, {
				...httpServerUpdate,
				auth: requireAuth && httpServer.auth.username && httpServer.auth.password ? httpServer.auth : null
			});
			// setRequestState('fetched');
		};

		const setEnabled = async (enabled) => {
			if (!httpServer.id) {
				const result = await doUpdateHttpServer(machineId, { enabled });
				if (result.httpServer) {
					setHttpServer({
						...result.httpServer,
						auth: {
							username: result.httpServer.auth ? result.httpServer.auth.username : null,
							password: result.httpServer.auth ? result.httpServer.auth.password : null
						},
						requireAuth: !!result.httpServer.auth
					});
				}
			} else {
				updateHttpServer({ enabled });
			}
		};

		useEffect(() => {
			if (settingsQuery.status === 'done') {
				const { machine } = settingsQuery.result.scopedByMachine;
				setHttpServer({
					...machine.httpServer,
					auth: {
						username: machine.httpServer.auth ? machine.httpServer.auth.username : null,
						password: machine.httpServer.auth ? machine.httpServer.auth.password : null
					},
					requireAuth: !!machine.httpServer.auth
				});
				setStreamsheets(machine.streamsheets);
			} else if (settingsQuery.status === 'error') {
				console.error(settingsQuery.result);
			}
		}, [settingsQuery]);

		const authIncompleteError = httpServer && httpServer.requireAuth && !(httpServer.auth.username && httpServer.auth.password);

		return (
			<SettingsPaper
				open={open}
				close={onClose}
				helpContext="httpserversettings"
				title={
					<FormattedMessage id="Extensions.HttpServer.Dialog.Title" defaultMessage="HTTP Server" />
				}
			>
				{httpServer ? (
					<FormGroup>
						<FormControl margin="dense">
							<FormControlLabel
								control={
									<Checkbox
										color="primary"
										checked={httpServer.enabled}
										onChange={(ev, checked) => setEnabled(checked)}
									/>
								}
								label={
									<FormattedMessage
										id="Extensions.HttpServer.Dialog.Enabled"
										defaultMessage="Enable HTTP Server"
									/>
								}
							/>
						</FormControl>
						{httpServer.enabled ? (
							<React.Fragment>
								<TextField
									variant="outlined"
									margin="dense"
									size="small"
									fullWidth
									label={
										<FormattedMessage
											id="Extensions.HttpServer.Dialog.Streamsheet"
											defaultMessage="Streamsheet"
										/>
									}
									select
									helperText={
										<FormattedMessage
											id="Extensions.HttpServer.Dialog.Streamsheet.Help"
											defaultMessage="Streamsheet which receives the messages in the inbox"
										/>
									}
									value={httpServer.streamsheetId}
									onChange={(event) => updateHttpServer({ streamsheetId: event.target.value })}
								>
									{streamsheets.map((streamsheet) => (
										<MenuItem value={streamsheet.id} key={streamsheet.id}>
											{streamsheet.name}
										</MenuItem>
									))}
								</TextField>
								{httpServer.path ? (
									<React.Fragment>
										<Typography
											color="textSecondary"
											display="block"
											variant="caption"
											style={{ fontSize: '0.65rem' }}
										>
											<FormattedMessage
												id="Extensions.HttpServer.Dialog.Url"
												defaultMessage="URL"
											/>
										</Typography>
										<TextField
											type="text"
											fullWidth
											value={`${getHostOrigin()}${httpServer.path}`}
											InputLabelProps={{ shrink: true }}
											InputProps={{ readOnly: true }}
											helperText={
												<FormattedMessage
													id="Extensions.HttpServer.Dialog.Url.Help"
													defaultMessage="Use this URL to send messages to the configured Streamsheet via HTTP. Any additional path segments will be part of the messages metadata."
												/>
											}
										/>
									</React.Fragment>
								) : null}
								<FormControl margin="dense">
									<FormControlLabel
										control={
											<Checkbox
												color="primary"
												checked={httpServer.expectResponse}
												onChange={(ev, checked) =>
													updateHttpServer({ expectResponse: checked })
												}
											/>
										}
										label={
											<FormattedMessage
												id="Extensions.HttpServer.Dialog.ExpectResponse"
												defaultMessage="Expect Response"
											/>
										}
									/>
									<FormHelperText>
										<FormattedMessage
											id="Extensions.HttpServer.Dialog.ExpectResponse.Help"
											defaultMessage="Send a custom HTTP response by using the HTTP.RESPOND Streamsheet function."
										/>
									</FormHelperText>
								</FormControl>
								<FormControl margin="dense">
									<FormControlLabel
										control={
											<Checkbox
												color="primary"
												checked={httpServer.requireAuth}
												onChange={(ev, checked) => updateHttpServer({ requireAuth: checked })}
											/>
										}
										label={
											<FormattedMessage
												id="Extensions.HttpServer.Dialog.RequireAuthentication"
												defaultMessage="Require Authentication"
											/>
										}
									/>
									<FormHelperText>
										<FormattedMessage
											id="Extensions.HttpServer.Dialog.RequireAuthentication.Help"
											defaultMessage={`Require "Basic Auth" for all HTTP requests.`}
										/>
									</FormHelperText>
								</FormControl>
								{httpServer.requireAuth ? (
									<React.Fragment>
										<TextField
											id="username"
											margin="dense"
											label={
												<FormattedMessage
													id="Extensions.HttpServer.Dialog.Username"
													defaultMessage="Username"
												/>
											}
											type="text"
											fullWidth
											value={httpServer.auth.username || ''}
											onChange={(ev) =>
												setHttpServer({
													...httpServer,
													auth: { ...httpServer.auth, username: ev.target.value }
												})
											}
											onBlur={() => updateHttpServer()}
										/>
										<TextField
											id="password"
											margin="dense"
											label={
												<FormattedMessage
													id="Extensions.HttpServer.Dialog.Password"
													defaultMessage="Password"
												/>
											}
											type="text"
											fullWidth
											value={httpServer.auth.password || ''}
											onChange={(ev) =>
												setHttpServer({
													...httpServer,
													auth: { ...httpServer.auth, password: ev.target.value }
												})
											}
											onBlur={() => updateHttpServer()}
										/>
										{authIncompleteError ? (
											<Typography color="error" style={{ marginTop: '8px' }}>
												<FormattedMessage
													id="Extensions.HttpServer.Dialog.Error.UsernamePasswordRequired"
													defaultMessage="Username and password required!"
												/>
											</Typography>
										) : null}
									</React.Fragment>
								) : null}
							</React.Fragment>
						) : null}
					</FormGroup>
				) : null}
			</SettingsPaper>
		);
	}
);
